import React, { useEffect, useState, useRef } from 'react';

import './PhotoOptions.css';
import OptionButton from './OptionButton';
import Spacer from './base/Spacer';
import Text from './base/Text';
import { uploadToS3 } from '../utils';

const PhotoOptions = ({ userId, setImage, setFileType }) => {

  const cameraInputRef = useRef<HTMLInputElement | null>(null);
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const handleCapture = async (event) => {
    const file = event.target.files[0];
    console.log("handling capture");
    if (file) {
      await uploadToS3(file, userId, setFileType);
      setImage(file);
    }
  };

  const uploadButtonClick = () => {
    uploadInputRef.current?.click();
    
  }; 

  /*      <div style={{ marginLeft: "75px" }}>
        <Text color="dark-gray" weight={400} size="16px">
          Start by uploading a photo of your clothing item below! <img className="bouncingArrow" src={process.env.PUBLIC_URL + "/assets/down-arrow-loop.png"} style={{ height: "60px", width: "60px", rotate: "235deg", marginLeft: "15px"  }}></img>
        </Text>
      </div>*/

  return (
    <Spacer gap={30}>
      <div className="photoOptions">
        <input
          type="file"
          accept="image/jpeg, image/png"
          onChange={handleCapture}
          ref={uploadInputRef}
          style={{ display: 'none' }}
        />
        <Spacer gap={6}>
          <OptionButton onClick={uploadButtonClick} text="UPLOAD IMAGE TO START"/>
        </Spacer>
      </div>
    </Spacer>
  );
};

export default PhotoOptions;
