import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';

import './PhotoOptions.css';
import OptionButton from './OptionButton';
import Spacer from './base/Spacer';
import Text from './base/Text';

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '300px',  // Set your desired width here
    fontFamily: 'Switzer'
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: 'Switzer', // Set your desired font family
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'Switzer', // Ensure consistency in selected value
    color: 'blue'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontFamily: 'Switzer', // Ensure consistency in selected value
  })
};

const PhotoInput = ({ setBrand, isRare, setIsRare }) => {

  const [brandOptions, setBrandOptions] = useState<any[]>([]);
  const [query, setQuery] = useState<string>("");

  const onClickBox = () => {
    setIsRare(!isRare);
  };

  useEffect(() => {
    async function setBrands() {

      if (query.length === 3) {
        const response = await fetch(`${process.env.REACT_APP_BE_URL}/get-brands`, {
          method: "POST",
          headers: {
            'Content-Type': "application/json"
          },
          body: JSON.stringify({
            query
          })
        });
  
        const respJson = await response.json();
  
        if (respJson["brands"]) {
          setBrandOptions(respJson["brands"].map(b => ({ label: b.slice(0, 1).toUpperCase() + b.slice(1), value: b })));
        }
      } else if (query.length < 3) {
        setBrandOptions([]);
      }
    }

    setBrands();
  }, [query])



  return (
    <Spacer gap={30}>
      <div className="photoOptions">
        <Spacer gap={8}>
          <Spacer gap={0}>
            <Text color="dark-gray" weight={400} size="20px">
              Brand
            </Text>
            <Text color="dark-gray" weight={400} size="12px">
              Start typing to see options!
            </Text>
          </Spacer>
          <div style={{ width: "100%"}}>
            <Select isClearable onChange={(option) => {
              console.log(option?.value);
              setBrand(option?.value);
            }} onInputChange={(i) => setQuery(i)} options={brandOptions} styles={customStyles} />
          </div>
        </Spacer>
        <Spacer gap={8}>
          <Text color="dark-gray" weight={400} size="20px">
            Is this item rare/unique?
          </Text>
          <input
            type="checkbox"
            checked={isRare}
            onChange={onClickBox}
            style={{
              height: "20px",
              width: "20px",
              backgroundColor: "white",
              border: "2px solid #ddd",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          />
        </Spacer>
      </div>
    </Spacer>
  );
};

export default PhotoInput;
