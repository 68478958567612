import { loadStripe } from '@stripe/stripe-js';
import s3 from "./s3";

let stripePromise;
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "");
  }
  return stripePromise;
};

export const makeCookie = (len: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < len) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const convertTimestampToDateStr = (timestampStr: string) => {
  // Create a new Date object from the timestamp string
  let date = new Date(timestampStr);

  // Define an array of month names
  const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
  ];

  // Extract the year, month, and day from the date
  let year = date.getFullYear();
  let month = monthNames[date.getMonth()];
  let day = date.getDate();

  // Format and return the date string
  return `${month} ${day}, ${year}`;
}

export const s3_url = (generation_id: string, num?: number) => {
  return `https://seller-images-milk.s3.amazonaws.com/${generation_id}/${num ?? "0"}.png`;
};

export const validateCookie = async (cookie: string | null | undefined) => {

  if (!cookie) return false;

  const response = await fetch(
    `${process.env.REACT_APP_BE_URL}/validate-cookie`,
    { 
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "cookie": cookie
      })
    }
  );

  try {
    const respJson = await response.json();
    if (response.status !== 200) return "";
  
    return respJson["user_id"];
  } catch (e) {
    return "";
  }
}

export const uploadToS3 = async (file: any, generationId: string, setFileType?: any, num?: string) => {
  // Create a new image element and set its source to the captured image.
  // Define the S3 bucket name and file name
  const bucketName = 'seller-images-milk';

  // Encode the image as a buffer
  console.log("IMAGE TYPE");
  console.log(file.type);
  const fileType = (file.type?.toLowerCase() || "") as string;
  let fileSuffix = fileType.includes("png")
    ? "png"
    : fileType.includes("jpeg")
    ? "jpeg"
    : fileType.includes("jpg")
    ? "jpg"
    : undefined;
  
  if (!fileSuffix) return false;

  setFileType && setFileType(fileSuffix);
  const fileName = `${generationId}/${num ?? "0"}.${fileSuffix}`; // Unique file name

  console.log("uploading");
  console.log(fileName);

  // Set up the parameters for the S3 upload
  const params = {
    Bucket: bucketName,
    Key: fileName,
    Body: file,
    ContentType: file.type, // Adjust the content type as needed
  };

  // Upload the image to S3
  s3.upload(params, (err, data) => {
    if (err) {
      console.error('Error uploading image to S3:', err);
      return false;
    } else {
      console.log('Image uploaded successfully:', data.Location);
      return true;
      // You can handle success here, such as displaying a success message to the user.
    }
  });

  return fileName;
}

export const copyImageInS3 = async (sourceKey: string, destinationNum: string) => {
  // Define the source and destination S3 bucket names
  console.log("SOURCE KEY");
  console.log(sourceKey);
  const sourceBucket = 'seller-images-milk'; // Assuming this is the source bucket
  const sourceKeySplit = sourceKey.split("/");

  if (sourceKeySplit[1].slice(0,1) == destinationNum) {
    console.log("returning early");
    console.log(sourceKey);
    console.log(destinationNum);
    return sourceKey;
  }

  const destinationKey = sourceKeySplit[0] + "/" + destinationNum + sourceKeySplit[1].slice(1);

  // Set up the parameters for copying the image
  const copyParams = {
    Bucket: sourceBucket,
    CopySource: `${sourceBucket}/${sourceKey}`,
    Key: destinationKey,
  };

  // Copy the image
  try {
    const copyResult = await s3.copyObject(copyParams).promise();
    console.log('Image copied successfully:', copyResult);
    return destinationKey;
  } catch (err) {
    console.error('Error copying image:', err);
    return false;
  }
};

// Usage example: copyImageInS3('path/to/sourceImage.jpg', 'path/to/destinationImage.jpg', 'destination-bucket-name');


export const deleteFromS3 = async (fileType: any, generationId: string, num?: string) => {
  // Create a new image element and set its source to the captured image.
  // Define the S3 bucket name and file name
  const bucketName = 'seller-images-milk';

  const fileName = `${generationId}/${num ?? "0"}.${fileType}`; // Unique file name

  // Set up the parameters for the S3 upload
  const params = {
    Bucket: bucketName,
    Key: fileName,
  };

  // Upload the image to S3
  s3.deleteObject(params, (err, data) => {
    if (err) {
      console.error('Error uploading image to S3:', err);
      return false;
    } else {
      console.log('Image uploaded successfully:', fileName);
      return true;
      // You can handle success here, such as displaying a success message to the user.
    }
  });
}

export const difference = (date: Date) =>{
  const now = new Date();
  const diffInMs = date.getTime() - now.getTime();
  
  const diffInSecs = Math.floor(diffInMs / 1000);
  const diffInMins = Math.floor(diffInSecs / 60);
  const hours = Math.floor(diffInMins / 60);
  const secs = diffInSecs % 60;
  const mins = diffInMins % 60;

  return { hours, mins, secs }
}
